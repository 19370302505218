<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.52187 12.5827C7.52187 11.6184 7.13515 11.4879 7.13515 11.0459C7.13515 10.9404 7.15524 10.8651 7.22053 10.7395L7.62231 9.99121C7.68258 9.88072 7.73783 9.79032 7.77801 9.71498V9.67983C7.32098 9.55427 7.04977 9.37849 6.82879 9.04701H6.78861L6.23615 10.0866C6.04028 10.4533 5.88459 10.4934 5.43258 10.4934C4.2674 10.4934 3.33325 11.4226 3.33325 12.5827C3.33325 13.7429 4.2674 14.667 5.43258 14.667C6.58772 14.667 7.52187 13.7429 7.52187 12.5827ZM6.57265 12.5827C6.57265 13.2055 6.05535 13.7077 5.43258 13.7077C4.78973 13.7077 4.28749 13.2055 4.28749 12.5827C4.28749 11.9499 4.78973 11.4477 5.43258 11.4477C6.05535 11.4477 6.57265 11.9499 6.57265 12.5827ZM13.2373 12.5827C13.2373 11.4226 12.3081 10.4934 11.1379 10.4934C10.7161 10.4934 10.5403 10.4633 10.3645 10.1369L5.82935 1.58887C5.03582 1.79478 4.87008 2.65862 5.33214 3.94936L6.89408 8.29367C7.12008 8.91643 7.43147 9.19266 8.00903 9.3584L8.38571 9.46387C8.65691 9.5392 8.78247 9.66476 8.99843 10.0816L9.34999 10.7395C9.42031 10.8751 9.4404 10.9455 9.4404 11.0459C9.4404 11.4778 9.04865 11.6235 9.04865 12.5827C9.04865 13.7429 9.98281 14.667 11.1379 14.667C12.3031 14.667 13.2373 13.7429 13.2373 12.5827ZM8.4058 8.1882C8.4058 8.40918 8.21997 8.60505 7.98894 8.60505C7.7529 8.60505 7.56707 8.40918 7.56707 8.1882C7.56707 7.95215 7.7529 7.7613 7.98894 7.7613C8.21997 7.7613 8.4058 7.95215 8.4058 8.1882ZM11.2334 3.94936C11.7105 2.6536 11.5347 1.79478 10.7211 1.58887L8.50624 5.77748L9.66138 7.96722H9.72667L11.2334 3.94936ZM12.283 12.5827C12.283 13.2055 11.7708 13.7077 11.1379 13.7077C10.5101 13.7077 9.99787 13.2055 9.99787 12.5827C9.99787 11.9499 10.5101 11.4477 11.1379 11.4477C11.7708 11.4477 12.283 11.9499 12.283 12.5827Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
