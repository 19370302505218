<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12.0022 12.7658C12.3256 12.7658 12.6619 12.5912 13.0111 12.242L19.889 5.39741C19.5749 5.13247 19.0333 5 18.2642 5H5.55006C4.86142 5 4.37425 5.12529 4.08855 5.37587L10.9933 12.242C11.3406 12.5912 11.6769 12.7658 12.0022 12.7658ZM3.27208 17.6216L8.98864 11.9455L3.29632 6.32687C3.13214 6.59771 3.05005 7.03602 3.05005 7.64179V16.3527C3.05005 16.9378 3.12406 17.3607 3.27208 17.6216ZM5.71867 19H18.4329C19.1427 19 19.6506 18.864 19.9565 18.5919L14.1321 12.8024L13.6724 13.2676C13.1304 13.7901 12.5736 14.0514 12.0022 14.0514C11.4272 14.0514 10.8697 13.7901 10.3295 13.2676L9.8669 12.8075L4.05323 18.5865C4.38758 18.8622 4.94273 19 5.71867 19ZM20.7188 17.6162C20.8631 17.3518 20.9353 16.9306 20.9353 16.3527V7.64179C20.9353 7.03963 20.8551 6.6067 20.6945 6.34299L15.0104 11.9455L20.7188 17.6162Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    iconColor: {
      type: String,
      default: '#593BD2',
    },
  },
};
</script>

<style lang="scss" scoped></style>
