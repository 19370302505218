export default {
  write_message: 'Написать сообщение',
  my_chats: 'Мои чаты',
  join_chat: 'Присоединиться к чату',
  for_all: 'Все',
  for_participants: 'Участники',
  for_participants_and_subscribers: 'Участники и подписчики',
  for_none: 'Никто',
  chat_settings: 'Настройки чата',
  chat_visibility: 'Видимость чата',
  chat_access: 'Доступ к чату',
  chat_participants: 'участник(-ов)',
};
