<template>
  <div>
    <div class="app-modal modal-action">
      <button class="modal-action__close-btn" @click="closeModal">
        <SvgClose />
      </button>
      <p class="modal-action__title">{{ title }}</p>
      <div class="modal-action__buttons">
        <button class="btn--blue modal__submit-btn" @click="submitAction">{{ $t('yes') }}</button>
        <button class="btn--blue modal__submit-btn" @click="closeModal">{{ $t('no') }}</button>
      </div>
    </div>
    <div class="modal-overlay"></div>
  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      title: this.params?.title,
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    submitAction() {
      this.$emit('close');
      this.$emit('submitted');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-action {
  max-width: 700px;

  &__close-btn {
    all: unset;
    cursor: pointer;
    display: block;
    margin-left: auto;
  }
  &__title {
    font-size: var(--text-xl);
    font-weight: 500;
    text-align: center;
    padding: 0 40px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 40px;
  }
}
</style>
