<template>
  <div class="avatar" :class="avatarClass">
    <img v-if="avatar" class="" :src="$host + avatar" alt="avatar" />
    <div v-else-if="initials" class="avatar__stump">
      <span>{{ initials }}</span>
    </div>
    <img v-else :src="defaultAvatar" alt="avatar" />
  </div>
</template>

<script>
const avatarTypesList = ['regular', 'middle', 'big', 'record', 'event', 'subscriber'];

export default {
  props: {
    avatar: {
      type: String,
    },
    initials: {
      type: String,
    },
    type: {
      type: String,
      default: 'regular',
      validator: (value) => avatarTypesList.includes(value),
    },
  },
  computed: {
    defaultAvatar() {
      //на проді немає цієї дефолтної аватарки і схоже на те, що вона в
      //додатку ніде не використовується (якщо в props avatar не пусте -
      //виводимо зображення, або ж ініціали)
      const PATH = 'https://megacampus-dev.s3.eu-central-1.amazonaws.com/';

      return PATH + 'default-avatar.png';
    },

    avatarClass() {
      return `avatar--${this.type}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 16px;
  overflow: hidden;

  & > * {
    height: 100%;
  }

  &--record,
  &--event {
    border: 1px solid var(--white);
    border-radius: 50%;
  }

  &--record {
    width: 20px;
    height: 20px;
  }

  &--event {
    width: 24px;
    height: 24px;
  }

  &--middle {
    width: 64px;
    height: 64px;
    border-radius: 8px;
    font-size: 32px;
  }

  &--subscriber {
    width: 76px;
    height: 76px;
    border-radius: 18px;

    @media (max-width: $sm) {
      width: 48px;
      height: 48px;
    }
  }

  &--big {
    width: 80px;
    height: 80px;
    border-radius: 18px;
    font-size: 32px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      border: 4px solid rgba(255, 255, 255, 0.5);
      box-sizing: border-box;
      border-radius: 18px;
    }
  }

  &__stump {
    display: grid;
    place-items: center;
    color: var(--white);
    background: var(--mid-gray);
    text-transform: uppercase;
  }
}
</style>
