<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3759 3.57357L13.8266 3.09882C14.055 2.85844 14.073 2.52792 13.8447 2.29956L13.6884 2.1373C13.4841 1.93298 13.1416 1.96303 12.9192 2.18538L12.4505 2.6421L13.3759 3.57357ZM6.26072 9.96764L7.49266 9.45083L12.9372 4.00625L12.0118 3.0868L6.5672 8.53739L6.02635 9.72726C5.96625 9.86548 6.1225 10.0217 6.26072 9.96764ZM3.97712 14H10.9782C12.132 14 12.811 13.3269 12.811 12.0409V5.69491L11.6091 6.89079V11.9327C11.6091 12.5097 11.3027 12.7981 10.9 12.7981H4.05524C3.50237 12.7981 3.20189 12.5097 3.20189 11.9327V5.25622C3.20189 4.67931 3.50237 4.39085 4.05524 4.39085H9.15728L10.3532 3.18896H3.97712C2.67907 3.18896 2 3.86202 2 5.14805V12.0409C2 13.3269 2.67907 14 3.97712 14Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
