<template>
  <div class="app-container">
    <div style="display: none" v-if="showDevelopmentParts">
      <ul style="display: flex; justify-content: center">
        <li v-for="(component, route, idx) in ROUTES" :key="route">
          <a :href="route" :class="`btn--${colors[idx]}`">{{ $t(component.toLowerCase()) }}</a>
        </li>
      </ul>

      <button @click="$i18n.locale = 'ru'">Ru</button>
      <button @click="$i18n.locale = 'en'">En</button>

      <input
        type="text"
        value="11835|V3c5lhjHVkmPinipOvfOLox614nrI39nmP5c88aS"
        style="display: none"
        id="app-token"
      />

      <input
        type="text"
        value="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvbWVnYWNhbXB1cy5jb20iLCJhdWQiOiJodHRwczpcL1wvbWVnYWNhbXB1cy5jb20iLCJpYXQiOjE2NTQ3NjU1NTYsIm5iZiI6MTY1NDc2NTU1NiwidXNlcklkIjoiODc1MTkiLCJ1c2VyTG9naW4iOiJtY0BlbXBhdC50ZWNoIiwidXNlclBhc3N3b3JkIjoiWDZhPCxIYzljNzdjMGEwZTk1ZGE2ZjI1NzAxNzgxMDA2N2Q4ZTljMSJ9.8mENvtg5Gz0av7pzPgHcCHcIoRLmUOxv_tvcGLRNv6g"
        style="display: none"
        id="web-token"
      />

      <!-- Прод токени -->
      <!-- <input
        type="text"
        value="13434|nNjnGRzLRkwDJn2bPpqcl2HE8o3sJXQsvlEskVNE"
        style="display: none"
        id="app-token"
      />

      <input
        type="text"
        value="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvbWVnYWNhbXB1cy5jb20iLCJhdWQiOiJodHRwczpcL1wvbWVnYWNhbXB1cy5jb20iLCJpYXQiOjE2NDc0MzQyODEsIm5iZiI6MTY0NzQzNDI4MSwidXNlcklkIjoiMTEyNjQ2IiwidXNlckxvZ2luIjoibWNAZW1wYXQudGVjaCIsInVzZXJQYXNzd29yZCI6Ilg2YTwsSGM5Yzc3YzBhMGU5NWRhNmYyNTcwMTc4MTAwNjdkOGU5YzEifQ.sYwW5NVCqf_X7VqlTE4DXNFsoiutoxKSdLGqwnqwh1w"
        style="display: none"
        id="web-token"
      /> -->
    </div>

    <!-- розкоментувати інпути при локальній роботі з продовськими данними -->
    <!-- <input
      type="text"
      value="13434|nNjnGRzLRkwDJn2bPpqcl2HE8o3sJXQsvlEskVNE"
      style="display: none"
      id="app-token"
    />

    <input
      type="text"
      value="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvbWVnYWNhbXB1cy5jb20iLCJhdWQiOiJodHRwczpcL1wvbWVnYWNhbXB1cy5jb20iLCJpYXQiOjE2NDc0MzQyODEsIm5iZiI6MTY0NzQzNDI4MSwidXNlcklkIjoiMTEyNjQ2IiwidXNlckxvZ2luIjoibWNAZW1wYXQudGVjaCIsInVzZXJQYXNzd29yZCI6Ilg2YTwsSGM5Yzc3YzBhMGU5NWRhNmYyNTcwMTc4MTAwNjdkOGU5YzEifQ.sYwW5NVCqf_X7VqlTE4DXNFsoiutoxKSdLGqwnqwh1w"
      style="display: none"
      id="web-token"
    /> -->

    <!-- розкоментувати інпути при локальній роботі з продовськими данними -->
    <!-- токени для Лесиного профіля -->
    <!-- <input
      type="text"
      value="14413|wLR5ycoJROhkGGeSCGYhmaYTJ9VkdmnawGONj5nP"
      style="display: none"
      id="app-token"
    />

    <input
      type="text"
      value="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvbWVnYWNhbXB1cy5jb20iLCJhdWQiOiJodHRwczpcL1wvbWVnYWNhbXB1cy5jb20iLCJpYXQiOjE2NDkyMzc1NDUsIm5iZiI6MTY0OTIzNzU0NSwidXNlcklkIjoiMTEzNjk2IiwidXNlckxvZ2luIjoibGNAZW1wYXQudGVjaCIsInVzZXJQYXNzd29yZCI6ImpMKjJMNCVmYzQzOWZjNTYyMjU5ZDUwM2E5Njc4YjFmYjhiODhlNDcifQ.1VsEp6iYRIZrGg2VRLVxwA9pqeAH2XC9-tK_CpWQ6Eo"
      style="display: none"
      id="web-token"
    /> -->

    <component :is="componentName"></component>
  </div>
</template>

<script>
import ENV from '../config/env.js';

const ROUTES = {
  '/app/my-campuses': 'MyCampuses',
  '/app/campuses': 'Campuses',
  '/app/campus': 'Campus',
  '/app/profile': 'Profile',
  '/app/chats': 'Chats',
  '/app/chat': 'Chat',
  '/app/event': 'Event',
  '/app/record': 'Record',
  '/app/campus-participants': 'CampusParticipants',
  '/app/user-followers': 'UserFollowers',
  '/app/user-followings': 'UserFollowings',
  '/app/campus-events': 'CampusEvents',
  '/app/campus-records': 'CampusRecords',
};

export default {
  components: {
    MyCampuses: () => import('./views/MyCampuses.vue'),
    Campuses: () => import('./views/Campuses.vue'),
    Campus: () => import('./views/Campus.vue'),
    Profile: () => import('./views/Profile/Profile.vue'),
    Chats: () => import('./views/Chats.vue'),
    Chat: () => import('./views/Chat.vue'),
    Event: () => import('./views/Event.vue'),
    Record: () => import('./views/Record.vue'),
    CampusParticipants: () => import('./views/CampusParticipants/CampusParticipants.vue'),
    UserFollowers: () => import('./views/UserFollowers.vue'),
    UserFollowings: () => import('./views/UserFollowings.vue'),
    CampusEvents: () => import('./views/CampusEvents.vue'),
    CampusRecords: () => import('./views/CampusRecords.vue'),
  },

  data() {
    return {
      token: null,
      ROUTES,
      colors: [
        'green',
        'red',
        'blue',
        'green',
        'red-outlined',
        'blue-outlined',
        'green-outlined',
        'green',
        'red',
        'blue',
        'green',
        'red-outlined',
        'blue-outlined',
      ],
      ENV,
    };
  },

  computed: {
    componentName() {
      const { pathname } = window.location;
      const [trailedPath] = pathname.split(/\/+$/gi);

      return ROUTES[trailedPath] || 'Campus';
    },
    showDevelopmentParts() {
      return ENV === 'local' ? true : false;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/index.scss';

.app-container {
  // padding: 16px 0;
}
</style>
