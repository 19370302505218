<template>
  <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9763 4.51074H3.49353L6.44192 1.67584C6.84118 1.29195 6.84118 0.671812 6.44192 0.287919C6.04266 -0.0959732 5.3977 -0.0959732 4.99844 0.287919L0.299445 4.80604C-0.0998152 5.18993 -0.0998152 5.81007 0.299445 6.19396L4.99844 10.7121C5.3977 11.096 6.04266 11.096 6.44192 10.7121C6.84118 10.3282 6.84118 9.70805 6.44192 9.32416L3.49353 6.47942H16.9763C17.5393 6.47942 18 6.03647 18 5.49508C18 4.95369 17.5393 4.51074 16.9763 4.51074Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    iconColor: {
      type: String,
      default: '#92A1BB',
    },
  },
};
</script>

<style lang="scss" scoped></style>
