import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { en } from './locales/en/index';
import { ru } from './locales/ru/index';
import App from './App.vue';
import ENV from '../config/env.js';
import '@/helpers/filters';

Vue.use(VueI18n);
Vue.config.productionTip = false;

if (ENV === 'local') {
  Vue.prototype.$host = 'https://megacampus-dev.s3.eu-central-1.amazonaws.com/';
} else {
  Vue.prototype.$host = 'https://megacampus-prod.s3.eu-central-1.amazonaws.com/';
}

const requireComponent = require.context(
  // The relative path of the components folder
  '../src/components/Icons',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /.(vue|js)$/,
);

requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);

  const componentName = fileName
    .split('/')
    .pop()
    .replace(/\.\w+$/, '');

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig,
  );
});

const requireUIComponent = require.context('../src/components/UI', false, /.(vue|js)$/);

requireUIComponent.keys().forEach((fileName) => {
  const componentUIConfig = requireUIComponent(fileName);

  const componentName = fileName
    .split('/')
    .pop()
    .replace(/\.\w+$/, '');

  Vue.component(componentName, componentUIConfig.default || componentUIConfig);
});

const messages = {
  en,
  ru,
};

//get current language from local storage
let locale = localStorage.getItem('app-language');
let currentLocale = locale || 'ru';

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: currentLocale, // set locale
  fallbackLocale: 'ru', // set locale
  messages, // set locale messages
});

new Vue({
  render: (h) => h(App),
  i18n,
}).$mount('#app');
