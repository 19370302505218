<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4967 14.0001H14.7067L14.4267 13.7301C15.6267 12.3301 16.2467 10.4201 15.9067 8.39014C15.4367 5.61014 13.1167 3.39014 10.3167 3.05014C6.08672 2.53014 2.52672 6.09014 3.04672 10.3201C3.38672 13.1201 5.60672 15.4401 8.38672 15.9101C10.4167 16.2501 12.3267 15.6301 13.7267 14.4301L13.9967 14.7101V15.5001L18.2467 19.7501C18.6567 20.1601 19.3267 20.1601 19.7367 19.7501C20.1467 19.3401 20.1467 18.6701 19.7367 18.2601L15.4967 14.0001ZM9.49672 14.0001C7.00672 14.0001 4.99672 11.9901 4.99672 9.50014C4.99672 7.01014 7.00672 5.00014 9.49672 5.00014C11.9867 5.00014 13.9967 7.01014 13.9967 9.50014C13.9967 11.9901 11.9867 14.0001 9.49672 14.0001Z"
      fill="#9599AB"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgSearch',
};
</script>

<style lang="scss" scoped></style>
