<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.10031 14.0003H10.8995C11.4718 14.0003 11.9094 13.8544 12.2123 13.5626C12.5152 13.2707 12.6666 12.8507 12.6666 12.3025V7.65886C12.6666 7.11067 12.5152 6.69065 12.2123 6.39881C11.9094 6.10697 11.4718 5.96105 10.8995 5.96105H9.61054V7.20902H10.7569C10.9571 7.20902 11.1102 7.2578 11.2161 7.35535C11.3221 7.45289 11.3751 7.60485 11.3751 7.81122V12.1501C11.3751 12.3549 11.3221 12.5064 11.2161 12.6048C11.1102 12.7032 10.9571 12.7523 10.7569 12.7523H5.24047C5.03856 12.7523 4.88547 12.7032 4.78118 12.6048C4.6769 12.5064 4.62475 12.3549 4.62475 12.1501V7.81122C4.62475 7.60485 4.6769 7.45289 4.78118 7.35535C4.88547 7.2578 5.03856 7.20902 5.24047 7.20902H6.41433V5.96105H5.10031C4.52964 5.96105 4.09247 6.10657 3.78878 6.3976C3.48509 6.68864 3.33325 7.10905 3.33325 7.65886V12.3025C3.33325 12.8523 3.48509 13.2727 3.78878 13.5638C4.09247 13.8548 4.52964 14.0003 5.10031 14.0003ZM7.99867 10.0266C8.16219 10.0266 8.29985 9.97182 8.41165 9.86219C8.52345 9.75252 8.57936 9.62272 8.57936 9.47277V4.76387L8.52928 4.05524L8.78957 4.43011L9.39531 5.05168C9.49707 5.1581 9.62305 5.2113 9.77324 5.2113C9.90337 5.2113 10.0193 5.16777 10.1211 5.0807C10.2229 4.99364 10.2738 4.88158 10.2738 4.74453C10.2738 4.61876 10.2221 4.50509 10.1186 4.40351L8.4567 2.86289C8.38161 2.79034 8.30611 2.73955 8.23019 2.71053C8.15426 2.6815 8.07709 2.66699 7.99867 2.66699C7.92191 2.66699 7.84599 2.6815 7.77091 2.71053C7.69582 2.73955 7.61906 2.79034 7.54064 2.86289L5.87871 4.40351C5.77692 4.50509 5.72603 4.61876 5.72603 4.74453C5.72603 4.88158 5.77609 4.99364 5.87621 5.0807C5.97632 5.16777 6.09229 5.2113 6.22411 5.2113C6.37595 5.2113 6.50277 5.1581 6.60455 5.05168L7.20775 4.43011L7.47056 4.05524L7.4205 4.76387V9.47277C7.4205 9.62272 7.47681 9.75252 7.58944 9.86219C7.70207 9.97182 7.83848 10.0266 7.99867 10.0266Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
