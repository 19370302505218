<template>
  <button @click="click" class="dots">
    <SvgDots />
  </button>
</template>

<script>
export default {
  name: 'MenuButton',
  methods: {
    click: function (evt) {
      this.$emit('click', evt.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.dots {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
</style>
