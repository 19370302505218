import common from './_common';
import chat from './_chat';
import campus from './_campus';
import record from './_record';
import profile from './_profile';

export const en = {
  ...common,
  ...chat,
  ...campus,
  ...record,
  ...profile,
};
