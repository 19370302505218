export default {
  subscribers: 'subscribers',
  participants_amount: 'Participants amount:',
  participants: 'participants',
  subscribe: 'Subscribe',
  subscribed: `You're subscribed`,
  campusChat: 'Campus chat',
  openChat: 'Open chat',
  events: 'Events',
  records: 'Records',
  courses: 'Courses',
  startCourse: 'Start course',
  continueCourse: 'Continue course',
  availableBySubscription: 'Available by subscription',
  start: 'Start',
  salesEndDate: 'Sales open till',
  from: 'From',
  month: 'mon',
  lessons: 'lessons',
  modal_subscription_title: 'For accessing record you need to buy a subscription',
  my_campuses: 'My campuses',
  my_campuses_subtitle: 'The most popular communities on the platform',
  moderator: 'creator',
  participant: 'participant',
  you_are_campus_participant: `You're campus participant`,
  join_campus: 'Join campus',
  apply_to_join_campus: 'Apply to join campus',
  application_under_consideration: 'Application under consideration',
  make_admin: 'Grant admin rights',
  remove_admin_rights: 'Remove admin rights',
  kick_off_campus: 'Kick off campus',
  courses_subtitle: 'Learn from star authors and recognized professionals',
  records_subtitle: 'Edit saved broadcasts and share them with other community members.',
  events_subtitle: 'Live stream and create a schedule with scheduled webinars on the calendar',
  type_campus_name: 'Type your request',
};
