<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0047 4C9.83154 4 9.55951 4.00938 8.70588 4.04688C7.85538 4.08439 7.27067 4.22192 6.76412 4.41883C6.23568 4.62512 5.78855 4.89705 5.34454 5.34401C4.8974 5.78785 4.62537 6.23481 4.419 6.75991C4.22201 7.26939 4.08442 7.85075 4.0469 8.70092C4.00938 9.55734 4 9.82926 4 12.0016C4 14.1739 4.00938 14.4458 4.0469 15.2991C4.08442 16.1492 4.22201 16.7337 4.419 17.2401C4.62537 17.7683 4.8974 18.2153 5.34454 18.6591C5.78855 19.103 6.23568 19.378 6.76099 19.5812C7.27067 19.7781 7.85226 19.9156 8.70275 19.9531C9.55638 19.9906 9.82841 20 12.0016 20C14.1747 20 14.4467 19.9906 15.3004 19.9531C16.1509 19.9156 16.7356 19.7781 17.2421 19.5812C17.7674 19.378 18.2146 19.103 18.6586 18.6591C19.1026 18.2153 19.3778 17.7683 19.581 17.2432C19.778 16.7337 19.9156 16.1524 19.9531 15.3022C19.9906 14.4489 20 14.177 20 12.0047C20 9.83239 19.9906 9.56046 19.9531 8.70717C19.9156 7.857 19.778 7.27251 19.581 6.76617C19.384 6.23481 19.112 5.78785 18.6648 5.34401C18.2208 4.90018 17.7737 4.62512 17.2484 4.42196C16.7387 4.22504 16.1571 4.08752 15.3066 4.05001C14.4499 4.00938 14.1778 4 12.0047 4ZM17.2382 7.72887C17.2382 8.26022 16.8069 8.68843 16.2787 8.68843C15.7473 8.68843 15.3191 8.25709 15.3191 7.72887C15.3191 7.19751 15.7504 6.7693 16.2787 6.7693C16.8069 6.7693 17.2382 7.20064 17.2382 7.72887ZM7.89294 12.0015C7.89294 9.73234 9.73465 7.89136 12.0047 7.89136C14.2748 7.89136 16.1165 9.73234 16.1165 12.0015C16.1165 14.2707 14.2748 16.1117 12.0047 16.1117C9.73465 16.1117 7.89294 14.2707 7.89294 12.0015ZM12.0047 14.6677C10.532 14.6677 9.33754 13.4737 9.33754 12.0015C9.33754 10.5294 10.532 9.33539 12.0047 9.33539C13.4775 9.33539 14.6719 10.5294 14.6719 12.0015C14.6719 13.4737 13.4775 14.6677 12.0047 14.6677Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    iconColor: {
      type: String,
      default: '#593BD2',
    },
  },
};
</script>

<style lang="scss" scoped></style>
