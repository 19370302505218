<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.1622 15.5H12.8168C13.453 15.5 13.8985 15.4061 14.1533 15.2182C14.4081 15.0304 14.5355 14.7587 14.5355 14.4031C14.5355 13.9083 14.3833 13.3859 14.079 12.8358C13.7746 12.2857 13.3379 11.7707 12.7688 11.2907C12.1997 10.8107 11.5124 10.4206 10.7068 10.1205C9.90108 9.82032 8.99536 9.67023 7.98957 9.67023C6.98792 9.67023 6.08428 9.82032 5.27866 10.1205C4.47304 10.4206 3.78463 10.8107 3.21343 11.2907C2.64223 11.7707 2.2055 12.2857 1.90325 12.8358C1.60105 13.3859 1.44995 13.9083 1.44995 14.4031C1.44995 14.7587 1.57628 15.0304 1.82894 15.2182C2.08161 15.4061 2.52602 15.5 3.1622 15.5ZM7.99584 8.29507C8.55853 8.29507 9.07643 8.14289 9.54956 7.83851C10.0226 7.53414 10.4016 7.12241 10.6865 6.60333C10.9714 6.08424 11.1138 5.50108 11.1138 4.85385C11.1138 4.22163 10.9712 3.65172 10.6858 3.14414C10.4004 2.63655 10.0212 2.23563 9.54805 1.94138C9.07498 1.64713 8.55757 1.5 7.99584 1.5C7.43511 1.5 6.91773 1.64924 6.44371 1.94773C5.96968 2.24621 5.58916 2.6503 5.30213 3.15998C5.01505 3.66967 4.87151 4.23901 4.87151 4.86801C4.87251 5.51008 5.01521 6.08959 5.29959 6.60653C5.58403 7.12348 5.96278 7.53414 6.43586 7.83851C6.90898 8.14289 7.42898 8.29507 7.99584 8.29507Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgPlay',
  props: {
    iconColor: {
      type: String,
      default: '#E73F34',
    },
  },
};
</script>

<style lang="scss" scoped></style>
