<template>
  <div class="app-input">
    <slot name="icon" />
    <input
      type="text"
      :value="value"
      @change="updateValue"
      v-if="type === 'input'"
      :placeholder="placeholder"
    />
    <textarea
      :value="value"
      @change="updateValue"
      v-if="type === 'textarea'"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
const types = ['input', 'textarea'];
export default {
  name: 'AppInput',
  props: {
    type: {
      type: String,
      default: 'input',
    },
    placeholder: {
      type: String,
    },
    value: {
      type: String,
    },
  },
  methods: {
    updateValue: function (evt) {
      this.$emit('input', evt.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-input {
  display: flex;
  align-items: center;
  border: 1px solid #f0f2f4;
  border-radius: 10px;
  padding: 16px;
  margin: 24px 0;

  input,
  textarea {
    border: none;
    background-color: transparent;
    flex: 1;
    border: none;
    font-size: 16px;

    &:focus-visible {
      outline: none;
    }
  }

  textarea {
    resize: none;
    font-family: inherit;
    font-size: inherit;
    min-height: 110px;
  }
}
</style>
