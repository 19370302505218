export default {
  my_income: 'Мой доход',
  subscriptions: 'подписок',
  interests: 'Интересы',
  authors_courses: 'Авторские курсы',
  upload_photo: 'Загрузить фото',
  profile_edition: 'Редактирование профиля',
  campuses_follower: 'Участник кампусов',
  campuses_moderator: 'Создатель кампусов',
  send_message: 'Написать',
  empty_courses: 'Нет созданных курсов',
  empty_events: 'Нет событий',
  empty_records: 'Нет записей',
  empty_campuses_follower: 'Нет кампусов',
  empty_campuses_moderator: 'Нет кампусов',
};
