<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M15.4443 13.2901L15.9126 10.2367H12.983V8.25612C12.983 7.42076 13.3923 6.60563 14.7045 6.60563H16.0364V4.00612C16.0364 4.00612 14.8276 3.7998 13.6719 3.7998C11.2591 3.7998 9.68204 5.26213 9.68204 7.90951V10.2367H7V13.2901H9.68204V20.6715C10.7757 20.8426 11.8893 20.8426 12.983 20.6715V13.2901H15.4443Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    iconColor: {
      type: String,
      default: '#593BD2',
    },
  },
};
</script>

<style lang="scss" scoped></style>
