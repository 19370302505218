<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M4.3167 7.59884H6.14714C7.0664 7.59884 7.60666 7.05858 7.60666 6.11513V4.34113C7.60666 3.39769 7.0664 2.85742 6.14714 2.85742H4.3167C3.40551 2.85742 2.85718 3.39769 2.85718 4.34113V6.11513C2.85718 7.05858 3.40551 7.59884 4.3167 7.59884ZM10.1386 7.59884H11.9691C12.8883 7.59884 13.4286 7.05858 13.4286 6.11513V4.34113C13.4286 3.39769 12.8883 2.85742 11.9691 2.85742H10.1386C9.21939 2.85742 8.67912 3.39769 8.67912 4.34113V6.11513C8.67912 7.05858 9.21939 7.59884 10.1386 7.59884ZM4.51022 6.35704C4.23606 6.35704 4.10704 6.21189 4.10704 5.92967V4.51853C4.10704 4.2363 4.23606 4.09116 4.51022 4.09116H5.95361C6.21971 4.09116 6.37292 4.2363 6.37292 4.51853V5.92967C6.37292 6.21189 6.21971 6.35704 5.95361 6.35704H4.51022ZM10.3241 6.35704C10.058 6.35704 9.92092 6.21189 9.92092 5.92967V4.51853C9.92092 4.2363 10.058 4.09116 10.3241 4.09116H11.7756C12.0417 4.09116 12.1868 4.2363 12.1868 4.51853V5.92967C12.1868 6.21189 12.0417 6.35704 11.7756 6.35704H10.3241ZM4.3167 13.2595H6.14714C7.0664 13.2595 7.60666 12.7112 7.60666 11.7677V10.0018C7.60666 9.05836 7.0664 8.51003 6.14714 8.51003H4.3167C3.40551 8.51003 2.85718 9.05836 2.85718 10.0018V11.7677C2.85718 12.7112 3.40551 13.2595 4.3167 13.2595ZM10.1386 13.2595H11.9691C12.8883 13.2595 13.4286 12.7112 13.4286 11.7677V10.0018C13.4286 9.05836 12.8883 8.51003 11.9691 8.51003H10.1386C9.21939 8.51003 8.67912 9.05836 8.67912 10.0018V11.7677C8.67912 12.7112 9.21939 13.2595 10.1386 13.2595ZM4.51022 12.0177C4.23606 12.0177 4.10704 11.8726 4.10704 11.5823V10.1792C4.10704 9.89698 4.23606 9.75183 4.51022 9.75183H5.95361C6.21971 9.75183 6.37292 9.89698 6.37292 10.1792V11.5823C6.37292 11.8726 6.21971 12.0177 5.95361 12.0177H4.51022ZM10.3241 12.0177C10.058 12.0177 9.92092 11.8726 9.92092 11.5823V10.1792C9.92092 9.89698 10.058 9.75183 10.3241 9.75183H11.7756C12.0417 9.75183 12.1868 9.89698 12.1868 10.1792V11.5823C12.1868 11.8726 12.0417 12.0177 11.7756 12.0177H10.3241Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
