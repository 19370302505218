<template>
  <svg width="434" height="49" viewBox="0 0 434 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="23.5" width="2" height="2" rx="1" fill="#007AFF" />
    <rect x="6" y="22.5" width="2" height="4" rx="1" fill="#007AFF" />
    <rect x="12" y="20" width="2" height="9" rx="1" fill="#007AFF" />
    <rect x="18" y="18" width="2" height="13" rx="1" fill="#007AFF" />
    <rect x="24" y="21" width="2" height="7" rx="1" fill="#007AFF" />
    <rect x="30" y="22" width="2" height="5" rx="1" fill="#007AFF" />
    <rect x="36" y="16.5" width="2" height="16" rx="1" fill="#007AFF" />
    <rect x="42" y="10.5" width="2" height="28" rx="1" fill="#007AFF" />
    <rect x="48" y="16.5" width="2" height="16" rx="1" fill="#007AFF" />
    <rect x="54" y="15" width="2" height="19" rx="1" fill="#007AFF" />
    <rect x="60" y="4.5" width="2" height="40" rx="1" fill="#007AFF" />
    <rect x="66" y="13.5" width="2" height="22" rx="1" fill="#007AFF" />
    <rect x="72" y="17.5" width="2" height="14" rx="1" fill="#007AFF" />
    <rect x="78" y="22" width="2" height="5" rx="1" fill="#007AFF" />
    <rect x="84" y="16.5" width="2" height="16" rx="1" fill="#007AFF" />
    <rect x="90" y="10.5" width="2" height="28" rx="1" fill="#007AFF" />
    <rect x="96" y="16.5" width="2" height="16" rx="1" fill="#007AFF" />
    <rect x="102" y="15" width="2" height="19" rx="1" fill="#007AFF" />
    <rect x="108" y="4.5" width="2" height="40" rx="1" fill="#007AFF" />
    <rect x="114" y="13.5" width="2" height="22" rx="1" fill="#007AFF" />
    <rect x="120" y="17.5" width="2" height="14" rx="1" fill="#007AFF" />
    <rect x="126" y="16.5" width="2" height="16" rx="1" fill="#007AFF" />
    <rect x="132" y="10.5" width="2" height="28" rx="1" fill="#007AFF" />
    <rect x="138" y="16.5" width="2" height="16" rx="1" fill="#007AFF" />
    <rect x="144" y="15" width="2" height="19" rx="1" fill="#007AFF" />
    <rect x="150" y="4.5" width="2" height="40" rx="1" fill="#007AFF" />
    <rect x="156" y="13.5" width="2" height="22" rx="1" fill="#007AFF" />
    <rect x="162" y="17.5" width="2" height="14" rx="1" fill="#007AFF" />
    <rect x="168" y="22" width="2" height="5" rx="1" fill="#007AFF" />
    <rect x="174" y="16.5" width="2" height="16" rx="1" fill="#007AFF" />
    <rect x="180" y="10.5" width="2" height="28" rx="1" fill="#007AFF" />
    <rect x="186" y="16.5" width="2" height="16" rx="1" fill="#007AFF" />
    <rect x="192" y="15" width="2" height="19" rx="1" fill="#007AFF" />
    <rect x="198" y="4.5" width="2" height="40" rx="1" fill="#007AFF" />
    <rect x="204" y="13.5" width="2" height="22" rx="1" fill="#007AFF" />
    <rect x="210" y="17.5" width="2" height="14" rx="1" fill="#007AFF" />
    <rect x="216" y="17.5" width="2" height="14" rx="1" fill="#007AFF" />
    <rect x="222" y="12.5" width="2" height="24" rx="1" fill="#007AFF" />
    <rect x="228" y="17.5" width="2" height="14" rx="1" fill="#007AFF" />
    <rect x="234" y="16.5" width="2" height="16" rx="1" fill="#007AFF" />
    <rect x="240" y="7.5" width="2" height="34" rx="1" fill="#007AFF" />
    <rect x="246" y="15.5" width="2" height="18" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="252" y="18.5" width="2" height="12" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="258" y="22.5" width="2" height="4" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="264" y="17.5" width="2" height="14" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="270" y="12.5" width="2" height="24" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="276" y="17.5" width="2" height="14" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="282" y="16.5" width="2" height="16" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="288" y="7.5" width="2" height="34" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="294" y="15.5" width="2" height="18" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="300" y="18.5" width="2" height="12" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="306" y="15" width="2" height="19" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="312" y="9" width="2" height="31" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="318" y="15" width="2" height="19" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="324" y="14" width="2" height="21" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="330" y="2" width="2" height="45" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="336" y="13" width="2" height="23" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="342" y="17" width="2" height="15" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="348" y="22" width="2" height="5" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="354" y="15" width="2" height="19" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="360" y="9" width="2" height="31" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="366" y="15" width="2" height="19" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="372" y="14" width="2" height="21" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="378" y="2" width="2" height="45" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="384" y="13" width="2" height="23" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="390" y="17" width="2" height="15" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="396" y="20" width="2" height="9" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="402" y="18" width="2" height="13" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="408" y="16.5" width="2" height="16" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="414" y="21" width="2" height="7" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="420" y="22" width="2" height="5" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="426" y="22.5" width="2" height="4" rx="1" fill="#007AFF" />
    <rect opacity="0.2" x="432" y="23.5" width="2" height="2" rx="1" fill="#007AFF" />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
