import { render, staticRenderFns } from "./SvgFacebook.vue?vue&type=template&id=58f2d394&scoped=true&"
import script from "./SvgFacebook.vue?vue&type=script&lang=js&"
export * from "./SvgFacebook.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58f2d394",
  null
  
)

export default component.exports