<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 6.24998L16 16.83C16 18.92 14.47 20.78 12.39 20.98C10.01 21.21 8 19.34 8 17L8 4.63998C8 3.32998 8.94 2.13998 10.24 2.00998C11.74 1.85998 13 3.02998 13 4.49998L13 15C13 15.55 12.55 16 12 16C11.45 16 11 15.55 11 15L11 6.24998C11 5.83998 10.66 5.49998 10.25 5.49998C9.84 5.49998 9.5 5.83998 9.5 6.24998L9.5 14.86C9.5 16.17 10.44 17.36 11.74 17.49C13.24 17.64 14.5 16.47 14.5 15L14.5 4.66998C14.5 2.57998 12.97 0.719978 10.89 0.519977C8.5 0.289978 6.5 2.15998 6.5 4.49998L6.5 16.77C6.5 19.64 8.6 22.21 11.46 22.48C14.75 22.78 17.5 20.22 17.5 17L17.5 6.24998C17.5 5.83998 17.16 5.49998 16.75 5.49998C16.34 5.49998 16 5.83998 16 6.24998Z"
      fill="#92A1BB"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
