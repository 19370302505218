<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.668 7.33337C11.7746 7.33337 12.6613 6.44004 12.6613 5.33337C12.6613 4.22671 11.7746 3.33337 10.668 3.33337C9.5613 3.33337 8.66797 4.22671 8.66797 5.33337C8.66797 6.44004 9.5613 7.33337 10.668 7.33337ZM5.33464 7.33337C6.4413 7.33337 7.32797 6.44004 7.32797 5.33337C7.32797 4.22671 6.4413 3.33337 5.33464 3.33337C4.22797 3.33337 3.33464 4.22671 3.33464 5.33337C3.33464 6.44004 4.22797 7.33337 5.33464 7.33337ZM5.33464 8.66671C3.7813 8.66671 0.667969 9.44671 0.667969 11V12C0.667969 12.3667 0.967969 12.6667 1.33464 12.6667H9.33463C9.7013 12.6667 10.0013 12.3667 10.0013 12V11C10.0013 9.44671 6.88797 8.66671 5.33464 8.66671ZM10.668 8.66671C10.4746 8.66671 10.2546 8.68004 10.0213 8.70004C10.0346 8.70671 10.0413 8.72004 10.048 8.72671C10.808 9.28004 11.3346 10.02 11.3346 11V12C11.3346 12.2334 11.288 12.46 11.2146 12.6667H14.668C15.0346 12.6667 15.3346 12.3667 15.3346 12V11C15.3346 9.44671 12.2213 8.66671 10.668 8.66671Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgPeople',
  props: {
    iconColor: {
      type: String,
      default: 'white',
    },
  },
};
</script>

<style lang="scss" scoped></style>
