<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M11.4704 10.1675C11.6512 10.3835 11.9676 10.3935 12.1986 10.1424C13.0373 9.24342 13.4994 8.01295 13.4994 6.68706C13.4994 5.36116 13.0273 4.11563 12.1986 3.2317C11.9676 2.98058 11.6512 2.99063 11.4704 3.20659C11.2946 3.40748 11.3498 3.66362 11.5457 3.8846C12.2087 4.61786 12.5753 5.60224 12.5753 6.68706C12.5753 7.76686 12.2087 8.75123 11.5457 9.48449C11.3498 9.70547 11.2946 9.96161 11.4704 10.1675ZM4.5245 10.1675C4.70028 9.96161 4.64001 9.70547 4.44917 9.48449C3.78622 8.75123 3.41959 7.76686 3.41959 6.68706C3.41959 5.60224 3.78622 4.61786 4.44917 3.8846C4.64001 3.66362 4.70028 3.40748 4.5245 3.20659C4.3437 2.99063 4.02729 2.98058 3.79626 3.2317C2.96758 4.11563 2.49548 5.36116 2.49548 6.68706C2.49548 8.01295 2.95754 9.24342 3.79626 10.1424C4.02729 10.3935 4.3437 10.3835 4.5245 10.1675ZM9.92852 8.70603C10.1093 8.92199 10.4508 8.92701 10.6618 8.6759C11.0987 8.14855 11.3448 7.44041 11.3448 6.68706C11.3448 5.92869 11.0987 5.22054 10.6618 4.69822C10.4508 4.44208 10.1093 4.45212 9.92852 4.66306C9.75776 4.86898 9.82807 5.11005 9.99381 5.34108C10.26 5.70268 10.4207 6.18483 10.4207 6.68706C10.4207 7.18427 10.27 7.67646 9.99381 8.02802C9.82807 8.25904 9.75776 8.50514 9.92852 8.70603ZM6.06635 8.70603C6.23209 8.50514 6.1668 8.25904 6.00106 8.02802C5.72484 7.67646 5.56914 7.18427 5.56914 6.68706C5.56914 6.18483 5.73488 5.70268 6.00106 5.34108C6.1668 5.11005 6.23209 4.86898 6.06635 4.66306C5.88555 4.45212 5.54403 4.44208 5.3331 4.69822C4.89615 5.22054 4.65006 5.92869 4.65006 6.68706C4.65006 7.44041 4.89615 8.14855 5.3331 8.6759C5.54403 8.92701 5.88555 8.92199 6.06635 8.70603ZM7.99493 12.5029C8.26613 12.5029 8.43689 12.307 8.43689 12.0107V7.55592C8.7533 7.3952 8.97428 7.06875 8.97428 6.68706C8.97428 6.13962 8.54236 5.7077 7.99493 5.7077C7.45251 5.7077 7.0206 6.13962 7.0206 6.68706C7.0206 7.06875 7.23656 7.40023 7.55798 7.55592V12.0107C7.55798 12.302 7.73879 12.5029 7.99493 12.5029Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
