export default {
  education: 'education',
  networking: 'networking',
  courses: 'courses',
  to_learn: 'to learn',
  details: 'details',
  events: 'events',
  records: 'records',
  back: 'back',
  last_activity: 'last activity 45 ',
  campuses: 'Campuses',
  mycampuses: 'My campuses',
  campus: 'Campus',
  profile: 'Profile',
  chats: 'Chats',
  chat: 'Chat',
  event: 'Event',
  record: 'Record',
  campusparticipants: 'Campus participants',
  userfollowers: 'Subscribers',
  userfollowings: 'Subscriptions',
  campusevents: 'Campus events',
  campusrecords: 'Campus records',
  save_changes: 'Save changes',
  save: 'Save',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
  copy_link: 'Copy link',
  edit: 'Edit',
  name: 'Name',
  last_name: 'Last name',
  nickname: 'Nickname',
  about_myself: 'About myself',
  community: 'Community',
  watch_all: 'Watch all',
  show_more: 'Show more',
  show_less: 'Show less',
  '': '',
};
