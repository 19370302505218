export default {
  hello: 'hello',
  education: 'образование',
  networking: 'нетворкинг',
  courses: 'курсы',
  to_learn: 'к обучению',
  details: 'подробнее',
  events: 'события',
  records: 'записи',
  back: 'назад',
  last_activity: 'последняя активность 45 мин назад',
  campuses: 'Кампусы',
  mycampuses: 'Мои кампусы',
  campus: 'Кампус',
  profile: 'Профиль',
  chats: 'Чаты',
  chat: 'Чат',
  event: 'Событие',
  record: 'Запись',
  campusparticipants: 'Участники кампуса',
  userfollowers: 'Подписчики',
  userfollowings: 'Подписки',
  campusevents: 'События кампуса',
  campusrecords: 'Записи кампуса',
  save_changes: 'Сохранить изменения',
  save: 'Сохранить',
  cancel: 'Отмена',
  yes: 'Да',
  no: 'Нет',
  copy_link: 'Скопировать ссылку',
  edit: 'Редактировать',
  name: 'Имя',
  last_name: 'Фамилия',
  nickname: 'Ник',
  about_myself: 'О себе',
  community: 'Сообщество',
  watch_all: 'Смотреть все',
  show_more: 'Больше',
  show_less: 'Свернуть',
  '': '',
};
