export default {
  subscribers: 'подписчиков',
  participants_amount: 'Количество участников:',
  participants: 'участников',
  subscribe: 'Подписаться',
  subscribed: 'Вы подписаны',
  campusChat: 'Чат кампуса',
  openChat: 'Войти в чат',
  events: 'События',
  records: 'Записи',
  courses: 'Курсы',
  startCourse: 'Начать курс',
  continueCourse: 'Продолжить курс',
  availableBySubscription: 'Доступен по подписке',
  start: 'Старт',
  salesEndDate: 'Продажи открыты до',
  from: 'От',
  month: 'мес',
  lessons: 'уроков',
  modal_subscription_title: 'Чтобы прослушать запись, оформите подписку',
  my_campuses: 'Мои кампусы',
  my_campuses_subtitle: 'Самые популярные комьюнити на платформе',
  moderator: 'cоздатель',
  participant: 'участник',
  you_are_campus_participant: 'Вы участник кампуса',
  join_campus: 'Вступить в кампус',
  apply_to_join_campus: 'Подать заявку на вступление в кампус',
  application_under_consideration: 'Заявка на рассмотрении',
  make_admin: 'Сделать модератором',
  remove_admin_rights: 'Убрать из модераторов',
  kick_off_campus: 'Выгнать из кампуса',
  courses_subtitle: 'Обучайтесь у звездных авторов и признанных профессионалов',
  records_subtitle:
    'Редактируйте сохраненные эфиры и делитесь ими с другими участниками сообщества.',
  events_subtitle:
    'Проводите прямые эфиры и создавайте расписание с запланированными вебинарами в календаре',
  type_campus_name: 'Введите запрос',
};
