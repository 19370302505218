export default {
  my_income: 'My income',
  subscriptions: 'subscriptions',
  interests: 'Interests',
  authors_courses: `Author's courses`,
  upload_photo: 'Upload photo',
  profile_edition: 'Profile edition',
  campuses_follower: 'Campuses follower',
  campuses_moderator: 'Campuses moderator',
  send_message: 'Message',
  empty_courses: 'No courses created',
  empty_events: 'No events',
  empty_records: 'No records',
  empty_campuses_follower: 'No campuses',
  empty_campuses_moderator: 'No campuses',
};
