export default {
  write_message: 'Type message',
  my_chats: 'My chats',
  join_chat: 'Join chat',
  for_all: 'All',
  for_participants: 'Participants',
  for_participants_and_subscribers: 'Participants and subscribers',
  for_none: 'None',
  chat_settings: 'Chat settings',
  chat_visibility: 'Chat visibility',
  chat_access: 'Chat access',
  chat_participants: 'participants',
};
