<template>
  <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.699707" width="28" height="28" rx="14" fill="white" fill-opacity="0.15" />
    <path
      d="M18.8591 18.2264L17.9991 17.3664V14.0331C17.9991 11.9864 16.9057 10.2731 14.9991 9.81973V9.36639C14.9991 8.81306 14.5524 8.36639 13.9991 8.36639C13.4457 8.36639 12.9991 8.81306 12.9991 9.36639V9.81973C11.0857 10.2731 9.99906 11.9797 9.99906 14.0331V17.3664L9.13906 18.2264C8.71906 18.6464 9.01239 19.3664 9.60572 19.3664H18.3857C18.9857 19.3664 19.2791 18.6464 18.8591 18.2264ZM16.6657 18.0331H11.3324V14.0331C11.3324 12.3797 12.3391 11.0331 13.9991 11.0331C15.6591 11.0331 16.6657 12.3797 16.6657 14.0331V18.0331ZM13.9991 21.3664C14.7324 21.3664 15.3324 20.7664 15.3324 20.0331H12.6657C12.6657 20.7664 13.2591 21.3664 13.9991 21.3664Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
