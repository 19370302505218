<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
    <path
      d="M0.105103 5.99999C0.105103 5.88247 0.127703 5.77513 0.172902 5.67795C0.218097 5.58078 0.285891 5.48699 0.376285 5.3966L5.65085 0.223729C5.80452 0.0745762 5.99209 0 6.21356 0C6.35819 0 6.4904 0.0350281 6.61017 0.105084C6.72994 0.175141 6.82486 0.268926 6.89492 0.38644C6.96497 0.503954 7 0.635027 7 0.779661C7 0.99661 6.9209 1.18644 6.76271 1.34915L1.99662 5.99999L6.76271 10.6508C6.9209 10.8136 7 11.0034 7 11.2203C7 11.3649 6.96497 11.496 6.89492 11.6135C6.82486 11.731 6.72994 11.8248 6.61017 11.8949C6.4904 11.965 6.35819 12 6.21356 12C5.99209 12 5.80452 11.9254 5.65085 11.7762L0.376285 6.60337C0.281374 6.51298 0.212448 6.41806 0.169507 6.31863C0.126571 6.21919 0.105103 6.11298 0.105103 5.99999Z"
      fill="#808080"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgChevron',
};
</script>
