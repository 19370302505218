<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.52306 10.3296C6.29612 10.5565 6.185 10.8096 6.18971 11.0886C6.19442 11.3677 6.31463 11.6251 6.55035 11.8609L9.2223 14.5328L6.8625 16.8926C6.68766 17.0674 6.53436 17.2579 6.4026 17.464C6.27084 17.6702 6.16568 17.8682 6.0871 18.058C6.00853 18.2479 5.95648 18.4099 5.93095 18.544C5.90538 18.6782 5.91044 18.7631 5.94614 18.7988C5.9797 18.8324 6.06355 18.8364 6.19769 18.8108C6.33179 18.7852 6.49378 18.7332 6.68365 18.6546C6.87353 18.576 7.07154 18.4709 7.27768 18.3391C7.48382 18.2074 7.67431 18.0541 7.84915 17.8793L10.209 15.5195L12.8808 18.1913C13.1192 18.4298 13.3773 18.5506 13.655 18.554C13.9328 18.5574 14.1852 18.4456 14.4121 18.2186C14.7711 17.8597 15.0332 17.416 15.1983 16.8876C15.3635 16.3592 15.42 15.7819 15.3679 15.1559C15.3159 14.5298 15.1422 13.8957 14.847 13.2537L17.003 10.7699C17.3943 10.8756 17.7665 10.9608 18.1197 11.0258C18.4728 11.0907 18.7947 11.1329 19.0854 11.1523C19.376 11.1717 19.6201 11.1686 19.8176 11.1432C20.1277 11.106 20.3684 11.0017 20.5397 10.8305C20.7055 10.6647 20.784 10.4698 20.7754 10.2458C20.7668 10.0218 20.6698 9.81718 20.4846 9.63198L15.1138 4.26117C14.9259 4.07327 14.7207 3.97568 14.498 3.96839C14.2753 3.9611 14.0811 4.04035 13.9153 4.20613C13.7441 4.37738 13.6385 4.61672 13.5985 4.92414C13.5731 5.12161 13.57 5.36566 13.5894 5.6563C13.6088 5.94694 13.6503 6.26952 13.7139 6.62403C13.7775 6.97854 13.8635 7.35012 13.9718 7.73877L11.488 9.89464C10.8459 9.59945 10.2119 9.42582 9.58584 9.37376C8.95978 9.3217 8.38255 9.37825 7.85415 9.54339C7.32574 9.70855 6.88205 9.97061 6.52306 10.3296Z"
      fill="#808080"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgPin',
};
</script>

<style lang="scss" scoped></style>
